import React from 'react'

import { Link } from 'gatsby'

import Seo from '../components/seo'
import Layout from '../components/layout'
import * as styles from '../components/index.module.css'
import * as background from '../components/products.module.css';

class RootIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo
          title="Building Digital Products"
          description="From concept to launch. All the way there."
          image="/og-image.png"
        />
        <div className={background.background}>
          <header className={styles.header}>
            <h1> Hi, I’m Marc Droger. <br/> I create <Link to='/products'>digital products</Link>.</h1>
            <p>From concept to launch. All the way there.</p>
          </header>
          <section className={styles.video}>
            <div className={styles.container}>
              <h2>Highlights</h2>
              <p>Websites, apps, games, a magazine, videos and prototypes. I create a lot.</p>
              <video muted autoPlay loop playsInline>
                <source src="/video/showreel.mp4" type="video/mp4" />
              </video>
              <Link className={styles.button} to="/products">Show Products</Link>
            </div>
          </section>
          <section className={styles.social}>
            <div className={styles.container}>
              <h2>Let’s connect!</h2>
              <p>Let's get in touch or feel free to look around on my portfolio website.</p>
              <ul>
                <li>
                  <a className={styles.socialLinkedin} href="https://linkedin.com/in/marcdroger" target="_blank" rel="noreferrer">
                    <img src="/icons/linkedin-color.svg" alt="linkedin Marc Droger" />
                  </a>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default RootIndex
