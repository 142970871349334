// extracted by mini-css-extract-plugin
export var button = "index-module--button--c1Yca";
export var container = "index-module--container--fk42z";
export var fadeIn = "index-module--fade-in---lCaB";
export var header = "index-module--header--ttLhU";
export var social = "index-module--social--a9pFo";
export var socialDribbble = "index-module--socialDribbble--NDDZi";
export var socialLinkedin = "index-module--socialLinkedin--QaUii";
export var socialVimeo = "index-module--socialVimeo--zYXTI";
export var socialYoutube = "index-module--socialYoutube--Uo3gB";
export var video = "index-module--video--7DFaq";